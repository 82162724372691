<template>
  <form-wizard-step
    title="Välj vecka & biljett"
    :validate="validate"
    @error="onValidationError"
  >
    <div v-if="availableWeeksForBirthday && availableWeeksForBirthday.length == 0">
      <p class="alert alert-warning">
        Tyvärr kunde vi inte hitta någon lämplig vecka för deltagarens ålder.
      </p>
    </div>
    <div class="form-group">
      <label :class="{ 'text-danger': hasError && !selectedWeek }">Välj vecka</label>
      <select class="form-control" v-model="selectedWeek">
        <option disabled :value="null" checked>Ingen vecka vald</option>
        <option v-for="week in availableWeeksForBirthday" :key="week.id" :value="week.id">
          {{ week.name }} ({{ week.lowerAge }}-{{ week.upperAge }}år),
          {{ week.startDate }} - {{ week.endDate }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label :class="{ 'text-danger': hasError && !selectedTicket }"
        >Välj biljettyp</label
      >
      <select class="form-control" v-model="selectedTicket">
        <option disabled :value="null" checked>Ingen biljett vald</option>
        <option
          v-for="ticketType in availableTicketTypes"
          :key="ticketType.id"
          :value="ticketType.id"
          :checked="ticketType == ticketType.id"
        >
          {{ ticketType.fullDescription }}
        </option>
      </select>
    </div>

    <form-wizard-next-button
      v-if="availableWeeksForBirthday && availableWeeksForBirthday.length > 0"
    />
  </form-wizard-step>
</template>

<script lang="ts">
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import gql from "graphql-tag";
import FormWizardStep from "@/components/FormWizard/FormWizardStep/FormWizardStep.vue";
import FormWizardNextButton from "@/components/FormWizard/FormWizardNextButton.vue";

@Component({
  components: {
    FormWizardStep,
    FormWizardNextButton,
  },
  apollo: {
    availableWeeksForBirthday: {
      query: gql`
        query($personalNumber: String!) {
          availableWeeksForBirthday(personalNumber: $personalNumber) {
            id
            name
            startDate
            endDate
            lowerAge
            upperAge
          }
        }
      `,
      variables() {
        return {
          personalNumber: this.personalNumber
        };
      },
    },
    availableTicketTypes: {
      query: gql`
        query($weekId: ID) {
          availableTicketTypes(weekId: $weekId) {
            id
            name
            price
            fullDescription
          }
        }
      `,
      variables() {
        return {
          weekId: this.selectedWeek,
        };
      },
    },
  },
})
export default class SelectWeekStep extends Vue {
  @Prop() birthday;
  @Prop() personalNumber;
  @PropSync("week", { type: String }) selectedWeek!: string | null;
  @PropSync("ticketType", { type: String }) selectedTicket!: string | null;
  hasError = false;
  availableWeeksForBirthday;
  availableTicketTypes;

  validate() {
    this.hasError = false;

    const validBirthday = this.availableWeeksForBirthday.some(
      (w) => w.id === this.selectedWeek
    );
    const validTicketType = this.availableTicketTypes.some(
      (t) => t.id === this.selectedTicket
    );

    if (!validBirthday) this.selectedWeek = null;
    if (!validTicketType) this.selectedTicket = null;

    return validBirthday && validTicketType;
  }

  onValidationError() {
    this.hasError = true;
  }
}
</script>
