<template>
  <form-wizard-step title="Deltagarens uppgifter" :validate="validate" @error="onValidationError" :completed-title="() => `${firstName} ${lastName} (${personalNumber})`">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !firstName}">Förnamn</label>
          <input class="form-control" :value="firstName" @input="(e) => $emit('update:firstName',  e.target.value)" />
        </div>
      </div>
      <div class=" col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !lastName}">Efternamn</label>
          <input class="form-control" :value="lastName" @input="(e) => $emit('update:lastName',  e.target.value)" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !personalNumber}">Personnummer</label>
          <input class="form-control" :value="personalNumber" @input="(e) => updateRelevantFields(e.target.value)" />
          <span class="form-text text-danger" v-if="hasError && !validPersonalNumber">Ange korrekt personnummer</span>
        </div>
      </div>
      <!-- <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !gender}">Juridiskt kön</label>
          <div>
            <div class="form-check form-check-inline mt-2">
              <input class="form-check-input" type="radio" :checked="gender == 'male'" name="gender" id="genderRadio1" value="male" @input="(e) => $emit('update:gender', e.target.value)">
              <label class="form-check-label" for="genderRadio1">Kille</label>
            </div>
            <div class="form-check form-check-inline mt-2">
              <input class="form-check-input" type="radio" :checked="gender == 'female'" name="gender" id="genderRadio2" value="female" @input="(e) => $emit('update:gender', e.target.value)">
              <label class="form-check-label" for="genderRadio2">Tjej</label>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <!-- <div class="col-sm-12">
        <div class="form-check mt-3 mb-4">
          <input id="computer" class="form-check-input" type="checkbox" v-model="computer" />
          <label for="computer" class="form-check-label">Deltagaren vill låna dator</label>
          <span class="form-text text-muted">För att göra lägret tillgängligt för många så är vår förhoppning att kunna erbjuda de som inte har tillgång till egen dator möjligheten att låna en dator. Är du i behov av en lånedator, visa ditt intresse här så gör vi vårt bästa för att lösa det. Detta är endast tillgängligt för de som kan hämta en eventuell dator i Linköping.</span>
        </div>
      </div> -->
      <div class="col-sm-12">
        <div class="form-check mt-3 mb-4">
          <input id="hasLiteracy" class="form-check-input" type="checkbox" v-model="hasLiteracy" />
          <label for="hasLiteracy" class="form-check-label" :class="{'text-danger': hasError && !hasLiteracy}">Deltagaren är läskunnig</label>
          <span class="form-text text-muted">För att delta på Code Summer Camp krävs det att deltagaren har god läskunnighet. Härmed bekräftar jag att mitt barn kan läsa och skriva.</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !selectedExperience}">Tidigare erfarenhet av programmering</label>
          <select class=" form-control" v-model="selectedExperience">
            <option :value="null" checked>Välj ett alternativ</option>
            <option v-for="item in experiences" :key="item.id" :value="item.name">{{item.name}}</option>
          </select>
         <span class="form-text text-muted" style="font-size: 0.8em;">1 = Inte provat tidigare, 5 = Mycket goda kunskaper.</span>
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{'text-danger': hasError && !streetAddress}">Gatuadress</label>
          <input class="form-control" :value="streetAddress" @input="(e) => $emit('update:streetAddress',  e.target.value)" />
        </div>
        <div class="row">
          <div class="col-4">
            <label :class="{'text-danger': hasError && !zipcode}">Postnummer</label>
            <input class="form-control" :value="zipcode" @input="(e) => $emit('update:zipcode',  e.target.value)" />
          </div>
          <div class="col">
            <label :class="{'text-danger': hasError && !city}">Ort</label>
            <input class="form-control" :value="city" @input="(e) => $emit('update:city',  e.target.value)" />
          </div>
        </div>
      </div>
      <div class="col">

      </div>
    </div>
    <form-wizard-next-button />
  </form-wizard-step>
</template>

<script lang="ts">
  import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
  import gql from "graphql-tag";

  import FormWizardStep from "@/components/FormWizard/FormWizardStep/FormWizardStep.vue";
  import FormWizardNextButton from "@/components/FormWizard/FormWizardNextButton.vue";

  @Component({
    components: {
      FormWizardStep,
      FormWizardNextButton
    },
    apollo: {
      validPersonalNumber: {
        query: gql`
        query($personalNumber: String!) {
            validPersonalNumber(personalNumber: $personalNumber)
          }
        `,
        variables() {
          return {
            personalNumber: this.personalNumber || "19970612-6035"
          };
        },
      },
    }
  })
  export default class ParticipantInfoStep extends Vue {
    @Prop() firstName;
    @Prop() lastName;
    @Prop() personalNumber;
    @Prop() birthday;
    @Prop() streetAddress;
    @Prop() zipcode;
    @Prop() city;
    @PropSync("experience", { type: String }) selectedExperience!: string;
    // @PropSync("needComputer", { type: Boolean }) computer!: Boolean;
    @PropSync("literacy", { type: Boolean }) hasLiteracy!: Boolean;
    hasError = false;
    validPersonalNumber;

    experiences = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" }
    ];

    updateRelevantFields(personalNumber: string) {
      let birthdaytemp = personalNumber.split('-')[0]
      let birthday = birthdaytemp.substring(0, 4) + '-' + birthdaytemp.substring(4, 6) + '-' + birthdaytemp.substring(6, 8)
      this.$emit('update:personalNumber', personalNumber)
      this.$emit("update:birthday", birthday);
    }

    validate() {
      this.hasError = false;
      console.log(this.validPersonalNumber, this.personalNumber)
      return (
        this.firstName &&
        this.lastName &&
        this.streetAddress &&
        this.zipcode &&
        this.city &&
        this.selectedExperience &&
        this.hasLiteracy &&
        this.validPersonalNumber
      );
    }

    onValidationError() {
      this.hasError = true;
    }
  }
</script>