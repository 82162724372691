import { Vue, Component, Prop, Inject, InjectReactive } from 'vue-property-decorator';
import { Collapse } from 'bootstrap.native'

@Component
export default class FormWizardStep extends Vue {
  /**
   * Step title
   */
  @Prop(String)
  title!: string;

  /**
   * Step title when completed
   */
  @Prop({ type: Function, default: () => null })
  completedTitle!: () => string;

  /**
   * Edit step label
   */
  @Prop({ type: String, default: 'Ändra' })
  editLabel!: string;

  /**
   * Skip step if it validates
   */
  @Prop({ type: Boolean, default: false })
  forwards!: boolean;

  /**
   * Require manual check
   */
  @Prop({ type: Boolean, default: false })
  audit!: boolean;

  /**
   * Add optional validation
   */
  @Prop({ default: () => () => true })
  validate!: () => boolean;

  /**
   * Run code before leave
   */
  @Prop({ default: () => () => true })
  beforeLeave!: () => Promise<any> | boolean;

  /**
   * Inject from FormWizard
   */
  @Inject()         wizardId!: string;
  @Inject()         registerWizardStep!: (component: Vue) => number;
  @Inject()         goToStep!: (step: number) => void;
  @InjectReactive() wizardActiveStep!: number;
  @InjectReactive() wizardVisitedSteps!: number;

  /**
   * Step number, provided by FormWizard
   */
  stepNum!: number;

  collapse!: any;

  created() {
    // Register wizard step
    this.stepNum = this.registerWizardStep(this);

    // Programmatically collapse modal on validation success´
    this.$on('enter', () => this.collapse.show());
  }

  mounted() {
    this.collapse = new Collapse(this.$refs.button);
  }

  /**
   * Unique wizard step id
   */
  get wizardStepId() {
    // @ts-ignore
    return `form-wizard-step-${this._uid}`;
  }

  /**
   * If step is currently expanded
   */
  get isActive() {
    return this.wizardActiveStep === this.stepNum;
  }

  /**
   * If step has been visited
   */
  get isDisabled() {
    return this.wizardVisitedSteps < this.stepNum;
  }

  /**
   * Check if wizard step is editable
   */
  get isEditable() {
    return !this.isActive && !this.isDisabled;
  }

  /**
   * Expand step content, used from FormWizard
   */
  public expandStep() {
    this.collapse.show();

    // Resolve promise when animation is completed
    return new Promise(resolve => {
      (this.$refs.collapse as HTMLElement).addEventListener('shown.bs.collapse', resolve, { once: true });
    });
  }

  /**
   * Go to next step
   */
  public nextStep() {
    return this.goToStep(this.wizardActiveStep + 1);
  }

  /**
   * Go to previous step
   */
  public prevStep() {
    return this.goToStep(this.wizardActiveStep - 1);
  }
}
