import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import Vue from 'vue';

Vue.use(VueApollo)

const apolloClient = new ApolloClient<any>({
  // You should use an absolute URL here
  uri: '/graphql'
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

export default apolloProvider;