import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)
const store = new Vuex.Store({
  strict: true, 
  state: {
    enrollment: {
      firstName: 'Johan',
      lastName: 'André', 
      gender: 0
    }
  },
  mutations: {
    updateFirstName (state, value) {
      state.enrollment.firstName = value
    },
    updateLastName (state, value) {
      state.enrollment.lastName = value
    },
    updateGender (state, value) {
      state.enrollment.gender = value
    }
  }
})

export default store;
