<template>
  <form-wizard-step
    title="Målsman uppgifter"
    :validate="validate"
    @error="onValidationError"
  >
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{ 'text-danger': hasError && !parentName }">Målsman namn</label>
          <input
            class="form-control"
            :value="parentName"
            @input="(e) => $emit('update:parentName', e.target.value)"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label :class="{ 'text-danger': hasError && !parentPhone }"
            >Målsman telefon</label
          >
          <input
            class="form-control"
            :value="parentPhone"
            @input="(e) => $emit('update:parentPhone', e.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label :class="{ 'text-danger': hasError && !email }">Målsman epost</label>
          <input
            class="form-control"
            :value="email"
            @input="(e) => $emit('update:email', e.target.value)"
          />
          <small id="emailHelp" class="form-text text-muted"
            >Vi kommer att skicka en bekräftelselänk till din epostadress som du måste
            klicka på för att slutföra anmälan.</small
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label>Kontaktperson namn (valfritt)</label>
          <input
            class="form-control"
            :value="secondParentName"
            @input="(e) => $emit('update:secondParentName', e.target.value)"
          />
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <label>Kontaktperson telefon (valfritt)</label>
          <input
            class="form-control"
            :value="secondParentPhone"
            @input="(e) => $emit('update:secondParentPhone', e.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Kontaktperson epost</label>
          <input
            class="form-control"
            :value="secondParentEmail"
            @input="(e) => $emit('update:secondParentEmail', e.target.value)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-check mt-3 mb-4">
          <input class="form-check-input" type="checkbox" v-model="gdpr" />
          <label class="form-check-label" :class="{ 'text-danger': hasError && !gpdr }"
            >Jag har tagit del av informationen kring lagring av uppgifter</label
          >
          <span class="form-text text-muted"
            >Vi lagrar de uppgifter som registreras i detta formulär för att underlätta
            genomförandet av Code Summer Camp avseende bland annat kommunikation med
            deltagare, marknadsföring av Code Summer Camp och andra aktiviteter som
            genomförs av Skill AB. Läs vår policy för hantering av personuppgifter
            <a
              href="https://skill.se/gdpr/"
              target="_blank"
              >här</a
            >. Vid frågor om Skills personuppgiftshantering eller önskemål om ändring,
            mejla <a href="mailto:gdpr@skill.se">gdpr@skill.se</a> eller ring vårt
            personuppgiftsombud på telefon 013-495 88 02.</span
          >
        </div>
      </div>
    </div>
    <form-wizard-next-button />
  </form-wizard-step>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";

import FormWizardStep from "@/components/FormWizard/FormWizardStep/FormWizardStep.vue";
import FormWizardNextButton from "@/components/FormWizard/FormWizardNextButton.vue";

@Component({
  components: {
    FormWizardStep,
    FormWizardNextButton,
  },
})
export default class ParentInfoStep extends Vue {
  @Prop() parentName;
  @Prop() parentPhone;
  @Prop() email;
  @Prop() secondParentName;
  @Prop() secondParentPhone;
  @Prop() secondParentEmail;

  hasError = false;
  gdpr = false;

  validate() {
    this.hasError = false;
    return this.parentName && this.parentPhone && this.email && this.gdpr;
  }

  onValidationError() {
    this.hasError = true;
  }
}
</script>
